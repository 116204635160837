/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";

// Internationalization
import en from "circle-flags/flags/us.svg";
import pl from "circle-flags/flags/pl.svg";
import { useTranslation } from "react-i18next";
import LanguageItem from "components/custom/lang/LanguageItem";
import { changeLanguage } from "i18next";

// auth
import { jwtDecode } from "jwt-decode";
import routes from "routes";
import checkAccessToken from "utils/checkAccessToken";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openi18nMenu, setOpeni18nMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const i18n = useTranslation("lang");
  const lang = localStorage.getItem("language");
  const navigate = useNavigate();
  const languages = [
    {
      code: i18n.t("en.code"),
      name: i18n.t("en.name"),
      desc: i18n.t("en.desc"),
      flag: en,
    },
    {
      code: i18n.t("pl.code"),
      name: i18n.t("pl.name"),
      desc: i18n.t("pl.desc"),
      flag: pl,
    },
  ];
  const [userData, setUserData] = useState([]);
  const isLoggedIn = !!localStorage.getItem("access_token");

  const switchLanguage = (code) => {
    if (code !== i18n.i18n.language) {
      changeLanguage(code);
      localStorage.setItem("language", code);
      handleClosei18nMenu();
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const isAuthenticated = !!accessToken;

    const redirectToLogin = () => {
      routes.forEach(({ key, route }) => {
        if (key === "login") {
          navigate(route);
        }
      });
    };
    if (!isAuthenticated) {
      redirectToLogin();
    } else {
      checkAccessToken();
      fetchUserStatus();
    }
    async function fetchUserStatus() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/v1/user/profile?lang=${lang}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            credentials: "include",
          }
        );
        const userData = await response.json();
        setUserData(userData.data);
        // Process the data here
      } catch (error) {
        console.error("Error fetching user status:", error);
      }
    }
  }, []);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handlei18nOpenMenu = (event) => setOpeni18nMenu(event.currentTarget);
  const handleClosei18nMenu = () => setOpeni18nMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="secondary"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  const renderi18nMenu = () => {
    return (
      <Menu
        anchorEl={openi18nMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openi18nMenu)}
        onClose={handleClosei18nMenu}
        sx={{ mt: 2 }}
      >
        {languages.map((language) => (
          <LanguageItem
            key={language.code}
            desc={language.desc}
            image={<img src={language.flag} alt={language.code} />}
            title={[language.name]}
            onClick={() => switchLanguage(language.code)} // Modify this line
          />
        ))}
      </Menu>
    );
  };

  const getCurrentLangFlag = () => {
    const currentLang = localStorage.getItem("language");
    const flag = languages.find((lang) => lang.code === currentLang)?.flag;
    return flag;
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SoftBox pr={1}>
              <SoftInput
                placeholder="Type here..."
                icon={{ component: "search", direction: "left" }}
              />
            </SoftBox>
            <SoftBox color={light ? "white" : "inherit"}>
              {!isLoggedIn ? (
                <Link to="/authentication/sign-in/basic">
                  <IconButton sx={navbarIconButton} size="small">
                    <Icon
                      sx={({ palette: { dark, white } }) => ({
                        color: light ? white.main : dark.main,
                      })}
                    >
                      account_circle
                    </Icon>
                    <SoftTypography
                      variant="button"
                      fontWeight="medium"
                      color={light ? "white" : "dark"}
                    >
                      Sign in
                    </SoftTypography>
                  </IconButton>
                </Link>
              ) : (
                <Link to="/profile">
                  <IconButton sx={navbarIconButton} size="small">
                    <Icon
                      sx={({ palette: { dark, white } }) => ({
                        color: light ? white.main : dark.main,
                      })}
                    >
                      <span style={{fontFamily: 'sans-serif', fontWeight: 500}}>
                        {userData.firstName && userData.lastName
                          ? userData.firstName.charAt(0).toUpperCase() +
                            userData.lastName.charAt(0).toUpperCase()
                          : "U"}
                      </span>
                    </Icon>
                  </IconButton>
                </Link>
              )}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
              </IconButton>
              <IconButton
                size="small"
                title="Change language"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="i18n-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handlei18nOpenMenu}
              >
                <img src={getCurrentLangFlag()} alt="en" width={20} />
              </IconButton>
              {renderMenu()}
              {renderi18nMenu()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
