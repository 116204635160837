/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// i18n
import { useTranslation } from "react-i18next";

// Data
import projectData from "assets/data/projects.json";

// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Auth
import checkAccessToken from "utils/checkAccessToken";
import Swal from "sweetalert2";

function Overview() {
  const i18n = useTranslation("profile");
  const projects = projectData;
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const isAuthenticated = !!accessToken;

    const redirectToLogin = () => {
      routes.forEach(({ key, route }) => {
        if (key === "login") {
          navigate(route);
        }
      });
    };
    if (!isAuthenticated) {
      redirectToLogin();
    } else {
      checkAccessToken();
      fetchUserStatus();
    }
    async function fetchUserStatus() {
      const lang = localStorage.getItem("language");
      console.log(lang);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/v1/user/profile?lang=${lang}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            credentials: "include",
          }
        ).then(async (response) => {
          if (response.ok) {
            const userData = await response.json();
            setUserData(userData.data);
            Swal.mixin({
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            }).fire({
              icon: "success",
              title: userData.message,
            });
          }
        });
      
        // Process the data here
      } catch (error) {
        console.error("Error fetching user status:", error);
      }
    }
  }, []);

  return (
    <DashboardLayout>
      <Header name={userData.firstName + " " + userData.lastName} org={userData.defaultOrg} />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <PlatformSettings />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title={i18n.t("profileInformation")}
              description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
              info={{
                fullName: userData.firstName + " " + userData.lastName,
                email: userData.email,
              }}
              social={[
                {
                  link: "https://www.facebook.com/CreativeTim/",
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                {
                  link: "https://twitter.com/creativetim",
                  icon: <TwitterIcon />,
                  color: "twitter",
                },
                {
                  link: "https://www.instagram.com/creativetimofficial/",
                  icon: <InstagramIcon />,
                  color: "instagram",
                },
              ]}
              action={{ route: "", tooltip: "Edit Profile" }}
            />
          </Grid>
          <Grid item xs={12} xl={4}>
            <ProfilesList title={i18n.t("conversations")} profiles={profilesListData} />
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mb={3}>
        <Card>
          <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
              <SoftTypography variant="h6" fontWeight="medium">
                {i18n.t("projects")}
              </SoftTypography>
            </SoftBox>
            {/* <SoftBox mb={1}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Architects design houses
              </SoftTypography>
            </SoftBox> */}
          </SoftBox>
          <SoftBox p={2}>
            <Grid container spacing={3}>
              {projects.map((project, index) => (
                <Grid item xs={12} md={6} xl={3} key={index}>
                  <DefaultProjectCard
                    image={project.image}
                    label={project.label}
                    title={project.title}
                    description={project.description}
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "info",
                      label: "view project",
                    }}
                    authors={project.authors}
                  />
                </Grid>
              ))}

              <Grid item xs={12} md={6} xl={3}>
                <PlaceholderCard title={{ variant: "h5", text: i18n.t("newProject") }} outlined />
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
