/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { CircularProgress, Autocomplete, TextField } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";

// i18n
import { useTranslation } from "react-i18next";

// Authentication
import validator from "validator";
import routes from "routes";
import i18next from "i18next";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [permissionError, setPermissionError] = useState("");
  const [authenticating, setAuthenticating] = useState(true);
  const navigate = useNavigate();

  const i18n = useTranslation("authentication");
  const i18nLang = useTranslation("lang");

  const languages = [
    {
      id: i18nLang.t("en.code"),
      label: i18nLang.t("en.name"),
      desc: i18nLang.t("en.desc"),
    },
    {
      id: i18nLang.t("pl.code"),
      label: i18nLang.t("pl.name"),
      desc: i18nLang.t("pl.desc"),
    },
  ];

  const currentLang = i18n.i18n.language;

  const changeLang = (e) => {
    const selectedLanguage = languages.find((lang) => lang.label === e.target.textContent);
    if (selectedLanguage) {
      i18next.changeLanguage(selectedLanguage.id);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignIn = (e) => {
    e.preventDefault();

    // Validate email
    // Validate email and password
    let isValid = true;

    if (!validator.isEmail(email)) {
      setEmailError(i18n.t("signIn.error.email"));
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!validator.isLength(password, { min: 4, max: 30 })) {
      setPasswordError(i18n.t("signIn.error.length"));
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (!isValid) return;
    const authUsername = process.env.REACT_APP_AUTH_USER;
    const authPassword = process.env.REACT_APP_AUTH_PASSWORD;

    const basicAuth = btoa(`${authUsername}:${authPassword}`);
    fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/auth/authenticate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${basicAuth}`,
      },
      credentials: "include",
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data.access_token && response.data.refresh_token) {
          if (response.data.access_token && response.data.refresh_token) {
            const roles = response.data.roles;
            if (roles.includes("ROLE_ADMIN")) {
              setError("");
              setAuthenticating(false);
              localStorage.setItem("access_token", response.data.access_token);
              localStorage.setItem("refresh_token", response.data.refresh_token);
              setTimeout(() => {
                routes.forEach(({ key, name, collapse }) => {
                  if (key === "dashboards") {
                    collapse.forEach((prop) => {
                      if (prop.key === "default") {
                        navigate(prop.route);
                      }
                    });
                  }
                });
              }, 250);
            } else {
              setPermissionError("You do not have the necessary permissions to access this page");
            }
          } else {
            setError("Invalid email or password");
          }
        } else {
          setError("Invalid email or password");
        }
      })
      .catch((error) => {
        setError("An error occurred while authenticating");
        console.error(error);
      });
  };

  return (
    <BasicLayout
      title={i18n.t("layout.welcome")}
      description={i18n.t("layout.welcomeDesc")}
      image={curved9}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {i18n.t("signIn.title")}
          </SoftTypography>
        </SoftBox>
        {/* <SoftBox mb={2}>
          <Socials />
        </SoftBox> */}
        <SoftBox p={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput
                type="email"
                placeholder={i18n.t("signIn.email")}
                onChange={handleEmailChange}
                error={emailError}
              />
              {emailError && (
                <SoftTypography variant="caption" color="error">
                  {emailError}
                </SoftTypography>
              )}
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                type="password"
                placeholder={i18n.t("signIn.password")}
                onChange={handlePasswordChange}
                error={passwordError}
              />
              {passwordError && (
                <SoftTypography variant="caption" color="error">
                  {passwordError}
                </SoftTypography>
              )}
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;{i18n.t("signIn.rememberMe")}
              </SoftTypography>
            </SoftBox>
            {error && (
              <SoftTypography variant="caption" color="error">
                {i18n.t("signIn.error.wrongInfo")}
              </SoftTypography>
            )}
            {permissionError && (
              <SoftTypography variant="caption" color="error">
                {permissionError}
              </SoftTypography>
            )}
            <SoftBox mt={4} mb={1}>
              <SoftButton
                disabled={!authenticating}
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSignIn}
              >
                {authenticating ? (
                  i18n.t("signIn.title")
                ) : (
                  <CircularProgress size={24} color="inherit" />
                )}
              </SoftButton>
            </SoftBox>
            <Separator />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(e) => changeLang(e)}
              disableClearable={true}
              options={languages}
              value={languages.find((lang) => lang.id === currentLang)}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} />}
            />
            {/* <SoftBox mt={1} mb={3}>
              <SoftButton
                component={Link}
                to="/authentication/sign-up/basic"
                variant="gradient"
                color="dark"
                fullWidth
              >
                sign up
              </SoftButton>
            </SoftBox> */}
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
