import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import routes from "routes";

const requestNewAccessToken = async (refreshToken) => {
  try {
    // Make API request to refresh access token using refresh token
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/v1/auth/refresh-token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken }),
      }
    );

    if (response.ok && response.status === 200) {
      const data = await response.json();
      const newAccessToken = data.accessToken;

      // Update the access token in local storage
      localStorage.setItem("access_token", newAccessToken);
    } else {
      // If the refresh token is invalid or expired, redirect to login page
      redirectToLogin();
    }
  } catch (error) {
    console.error("Error refreshing access token:", error);
    // Handle error
  }
};

const redirectToLogin = () => {
  const navigate = useNavigate();
  routes.forEach(({ key, route }) => {
    if (key === "login") {
      navigate(route);
    }
  });
};

const CheckAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");

  if (accessToken) {
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      requestNewAccessToken(refreshToken);
    }
  } else {
    localStorage.clear();
    redirectToLogin();
  }

  return null; // or you can return some JSX if needed
};

export default CheckAccessToken;
